.Chips {
  text-align: center;
  margin-top: 45px;
}
.Chip {
  margin: 8px;
}
.ChipCount {
  opacity: 0.5;
  margin-left: 8px;
}
