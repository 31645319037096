.RichText {
  a {
    color: theme(colors.orange);
    font-weight: 600;
  }

  a:not([href*=".finishers.com/"]):not([href^="/"]) {
    color: inherit;
    font-weight: 600;
  }

  a:hover {
    color: theme(colors.orange / 80);
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold mb-4 mt-8;
  }
  h2 {
    @apply text-2xl lg:text-3xl;
  }
  h3 {
    @apply text-xl lg:text-2xl;
  }

  p,
  li {
    line-height: 1.7;
  }

  p,
  ul,
  ol {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    padding-left: 1.5rem;
  }

  li {
    margin: 8px 0;
  }
  li p {
    margin: 0 !important;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  blockquote {
    border-left: 5px solid rgb(var(--color-electric-blue));
    font-style: italic;
    margin-left: 0;
    padding-left: 16px;
    * {
      font-style: italic;
      opacity: 0.9;
    }
  }
}
