.BackgroundImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #272f4c;
}

.Container {
  color: white;
  text-align: center;
  padding-top: 140px;
  padding-bottom: 140px;
}

@media screen and (max-width: calc(theme(screens.md) - 0.05px)) {
  .Container {
    padding: 60px 0 80px;
  }
}

@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .Container :global(.typo-h1) {
    font-size: 10vw;
  }
}
