.PictureContent {
  /* @apply absolute top-0 left-0 w-full h-full; */
  /* backdrop-filter donnait de droles de résultats sur chrome*/
  /* on a remplacé ça par des filtres cloudinary */
  /* @apply bg-dark-blue/50 backdrop-saturate-150 backdrop-contrast-125; */
  @apply flex flex-col p-6 @3xl:p-8 @4xl:p-10 @5xl:p-12 @6xl:p-14 @7xl:p-16;
  @apply text-white;
  /* @apply md:pb-0; */
  background: rgb(var(--color-dark-blue), 0.17);
}

.TopContent {
  @apply flex flex-col-reverse sm:gap-10 sm:flex-row justify-between grow-0;
}

.Location {
  @apply text-xs sm:text-base text-white/90;
}

.Date {
  @apply @2xl:text-base @3xl:text-lg @4xl:text-xl whitespace-nowrap;
  font-weight: 600;
}

.MainContent {
  @apply flex flex-col justify-center grow gap-4;

  @apply py-6 @md:py-12 @xl:py-16;
}

.Disciplines span,
.Distances span {
  @apply inline-block py-1 rounded-sm mr-2 md:mr-3 text-[11px] md:text-base px-2;
}

.Disciplines span {
  @apply bg-dark-blue uppercase mt-2 md:mt-3;
  font-family: var(--font-family-title);
}
.Distances span {
  @apply border border-white font-bold mb-2 md:mb-3;
}

@keyframes scrollBg {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -250px; /* Largeur du background */
  }
}

.Actions {
  @apply mt-1 md:mt-6 space-x-6;
}
