.Item {
  transition: box-shadow 0.2s;
  @apply bg-white;
  color: rgb(var(--color-text-default));
  text-decoration: none;
}

.Item:hover {
  box-shadow: 0 2px 6px rgb(14 20 35 / 12%);
}

:global(.SectionWhite) .Item {
  @apply bg-snow-grey;
}

.Label {
  @apply flex flex-row items-center capitalize font-bold;
}
.Stat {
  @apply text-sm text-fjord-grey;
}
@media screen(sm) {
  .Item {
    padding: theme(spacing.3) theme(spacing.2);
  }
  .Label {
    @apply text-lg;
  }
  .Stat {
    @apply text-base;
  }
  .Label,
  .Stat {
    @apply leading-snug;
  }
}
@media screen(lg) {
  .Item {
    padding: theme(spacing.5) theme(spacing.2);
  }
}
