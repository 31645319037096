.BlogPostTitle {
  font-weight: 700;
  font-size: 24px;
}
.MainImageOverlay {
  height: 100%;
  background-image: linear-gradient(338deg, #070f2891, #070f2800);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px;
  position: relative;
}
.PublishedByAt {
  color: #070f2869;
  font-size: 14px;
  font-weight: 600;
  bottom: 12px;
  right: 86px;
}
.Avatar :global(.MuiAvatar-root) {
  margin-left: 16px;
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: -20px;
  right: 16px;
}
.BlogPostDescription {
  color: rgb(var(--color-fjord-grey));
  font-size: 14px;
  margin-top: 8px;
}
