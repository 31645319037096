.Title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 320px;
  padding-bottom: 110px;
}

@media screen and (max-width: calc(theme(screens.lg) - 0.05px)) {
  .Title {
    height: 300px;
  }
}
@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .Title {
    height: auto;
    min-height: 180px;
  }
}
