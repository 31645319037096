.RichText :global(.kavval-video) {
  cursor: pointer !important;
  margin-bottom: 1rem;
  max-width: 600px;
  margin: 2rem auto;
  min-height: 300px;
  position: relative;
  transition: max-width 0.5s;
}
.RichText :global(.kavval-video):global(.kavval-video-embedded) {
  max-width: 100%;
}
.RichText :global(.kavval-video) > div {
  border-radius: 6px;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.RichText :global(.kavval-video) > div > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.RichText :global(.kavval-video)::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  border-radius: 6px;
  color: white;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='256' height='256' xml:space='preserve'%3E%3Cg style='stroke:none;stroke-width:0;stroke-dasharray:none;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;fill:none;fill-rule:nonzero;opacity:1'%3E%3Cpath d='M88.1 23.3c-1-3.8-4-6.9-8-8-7-1.8-35.1-1.8-35.1-1.8s-28.1 0-35.2 1.9c-3.8 1-6.9 4-8 8C0 30.3 0 45 0 45s0 14.6 1.9 21.7c1 3.8 4 6.9 8 8 7 1.8 35.1 1.8 35.1 1.8s28.1 0 35.2-1.9c3.8-1 6.9-4 8-8C90 59.7 90 45 90 45s0-14.6-1.9-21.7z' style='stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;fill:red;fill-rule:nonzero;opacity:1' transform='matrix(2.8 0 0 2.8 1.4 1.4)'/%3E%3Cpath style='stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;fill:%23fff;fill-rule:nonzero;opacity:1' transform='matrix(2.8 0 0 2.8 1.4 1.4)' d='M36 58.5 59.4 45 36 31.5z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 70px;
  background-position: center center;
  transition: background-color 0.2s;
}

.RichText :global(.kavval-video):hover::after {
  background-color: rgba(0, 0, 0, 0.5);
}

.RichText :global(.kavval-video):global(.kavval-video-embedded)::after {
  display: none !important;
}

.RichText :global(.ql-cloudinary-image) {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
}

.RichText :global(.ql-cloudinary-image):hover {
  filter: brightness(0.8);
  cursor: zoom-in;
}
